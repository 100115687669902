html {
  background: #042A2B;
  overflow: hidden;
}

body {
  margin: 0 auto;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  text-align: center;
  height: 100vh;
  width: 100%;
  margin: 0;
  background: #042A2B;
  background-size: cover;
  display: flex;
  flex-direction: row-reverse;
  overflow: auto;
}

.wrapper {
  width: 100%;
  height: 100%;
}

@media screen and (max-width:1000px) {
  .App {
    flex-direction: column;
  }
  .upgrade-container {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

.EmbersByClickSec {
  display: inline-block;
}

.clicker-area {
  /*background: aqua;*/
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 65%;
  overflow: hidden;
  background-image: url('images/CedarShadow.png'), url('images/CedarShadow.png');
  background-repeat: no-repeat;
  background-size: 50% 100%, 50% 100%;
  background-position: -46% top, 153% top;
}
.clicker-button {
  text-align: center;
  position: relative;
  padding-bottom: 40px;
  overflow: hidden;
  height: 100%;
  box-sizing: border-box;
  background: radial-gradient( 300px 450px at 50% 91%, #cb7127, #ffbd3851, transparent 70%);
}
@media screen and (max-width: 500px) {
  .clicker-button {
    padding-bottom: 50px;

  }
}
/*TODO - make radialGradient apart of javascript*/
.clicker-button > img {
  height: 100%;
  width: auto;
  transform-origin: bottom center;
  animation-name: sway;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.growing-flame-container {
  display: grid;
  /*background: #0C0C0C;*/
  justify-content: center;
  align-content: flex-end;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
}
.growing-flame-container > img {
  transform-origin: bottom center;
  animation-name: sway;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transition: height ease-out 7s;
}

.spark {
  display: block;
  position: absolute;
  height: auto;
  width: 50px;
  opacity: 5%;
  pointer-events: none;
}

.spark > img {
  width:100%;
  height: 100%;
}

.counters {
  color: white;
  display: block;
  position: absolute;
  width: 100%;
  height: 70px;
  margin-top: 50px;
  box-sizing: border-box;
  /* font-size: 3vw; */
  z-index: 9;
}


.ember-count {
  font-size: xx-large;
  /* line-height: min(1.5em, 120%); */
  overflow: scroll;
}
.ember-count::-webkit-scrollbar {
  display: none;
}

.ember-per-second {
  display: inline-block;
  font-size: x-large;
  padding-right: 20px;
}

.EmbersByClickSec {
  font-size: x-large;
}
@media screen and (max-width:500px) {
  .counters {
    margin-top: 70px;
  }
  .ember-count {
    font-size: medium;
  }
  .ember-per-second {
    font-size: medium;
  }
  .EmbersByClickSec {
    font-size: medium;
  }
}

.upgrade-container {
  position: relative;
  float: left;
  background: #000000;
  display: block;
  min-width: 100px;
  height: 100vh;
  
}
.upgrade-container::-webkit-scrollbar {
  display: none;
}

.upgrade-quantity {
  color: white;
}

.upgrade-label {
  font-size: 30px;
  color: #F47D20;
  margin-top: 30px;
  margin-bottom: 20px;

  overflow: scroll;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: block;
  line-height: 1em; /* a */
  max-height: 1em;
}

.upgrade-name {
  position: absolute;
  color: white;
  top: 2px;
  left: 13px;
  font-size: 17px;
}

.upgrade-level {
  position: absolute;
  color: white;
  right: 20px;
  top: 2px;
}

.upgrade-cost {
  position: absolute;
  color: white;
  right: 20px;
  bottom: 5px;

  overflow: hidden;
  text-overflow: scroll;
  word-wrap: break-word;
  display: block;
  line-height: 1em; /* a */
  max-height: 1em;
}

.upgrades {
  margin-top: 10px;
  margin-right: 5px;
}

.upgrade-available {
  position: relative;
  background: linear-gradient(90deg, rgba(255, 153, 0, 0.5) 0%, rgba(202, 202, 202, 0.12) 46.18%), rgba(135, 142, 136, 0.5);
  border-radius: 0 15px 15px 0;
  margin: 10px 0;
  height: 60px;
}

.upgrade-available:hover {
  position: relative;
  background: linear-gradient(90deg, rgba(189, 34, 0, 0.5) 0%, rgba(255, 153, 0, 0.5) 43.06%, rgba(85, 105, 103, 0.5) 77.43%), rgba(135, 142, 136, 0.5);  margin: 10px 0;
  height: 60px;
  box-shadow: #ffa400 0 0 5px;
  animation-duration: .25s;
}

.upgrade-available:active {
  position: relative;
  background: linear-gradient(90deg, rgba(189, 34, 0, 0.5) 17.01%, rgba(255, 153, 0, 0.5) 100%), rgba(135, 142, 136, 0.5);  margin: 10px 0;
  height: 60px;
}
.upgrade-unavailable {
  position: relative;
  background: rgba(135, 142, 136, 0.5);
  border-radius: 0 20px 20px 0;
  margin: 10px 0;
  height: 60px;
}

.upgrade-unavailable:active {
  position: relative;
  background: rgba(135, 142, 136, 0.5);
  margin: 10px 0;
  /*height: 60px;*/
  box-shadow: none;
}

.click-upgrades {
  /*margin-right: 5px;*/
}

.click-upgrade-available {
  position: relative;
  display: inline-block;
  background: linear-gradient(90deg, rgba(255, 153, 0, 0.5) 0%, rgba(202, 202, 202, 0.12) 46.18%), rgba(135, 142, 136, 0.5);

  height: 50px;
  width: 170px;
  margin: 10px;
}

.click-upgrade-available:hover {
  position: relative;
  background: linear-gradient(90deg, rgba(189, 34, 0, 0.5) 0%, rgba(255, 153, 0, 0.5) 43.06%, rgba(85, 105, 103, 0.5) 77.43%), rgba(135, 142, 136, 0.5);
  margin: 10px;
  height: 50px;
  animation-duration: .25s;
}

.click-upgrade-available:active {
  position: relative;
  background: linear-gradient(90deg, rgba(189, 34, 0, 0.5) 17.01%, rgba(255, 153, 0, 0.5) 100%), rgba(135, 142, 136, 0.5);  box-shadow: 0 0 3px;
}

.click-upgrade-unavailable {
  position:relative;
  width: 170px;
  display: inline-block;
  background: rgba(135, 142, 136, 0.5);
  margin: 10px;
  height: 50px;
}

.click-upgrade-unavailable:active {
  position:relative;
  width: 170px;
  display: inline-block;
  background: rgba(135, 142, 136, 0.7);
  margin: 10px;
  height: 50px;
}

.upgrades > hr {
  border: 1px solid black;
  margin-right: 5px;
}

.statistics {
  background: antiquewhite;
  border-style: double;
  margin: 0 20px;
}

@keyframes boxHighlight {
  from {
    background: #ffa400;
  }

  to {
    background: #fdba0e;
  }
}

@keyframes sway {
  from {
    transform: rotate(0);
  }

  50% {
    transform: rotate(-6deg);
  }

  to {
    transform: rotate(8deg);
  }
}

#spark {
  animation: sway 2s;
}

/* Fire css styles */
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ec6f59;
}
.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #fad15c;
}
#confetti{
  position: absolute;
  top: 0;
  pointer-events: none;
}